import React from 'react';

import './styles/global.scss';

import { Routes } from './routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
