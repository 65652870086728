import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';

import api from '../../services/api';

import styles from './cinema.module.scss';

export function Cinema(){

  const [banner, setBanner] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {

    async function loadBanner() {

      const response = await api.get(process.env.REACT_APP_BANNER_CINEMA_SOBRE);

      setBanner(response.data);    
    }

    loadBanner();
  }, []);


  useEffect(() => {

    async function loadContent() {

      const response = await api.get(process.env.REACT_APP_CINEMA);

      setContent(response.data);    
    }

    loadContent();
  }, []);


  return (
   <>
    <Helmet>
        {
          banner.map(banner => (
            <meta name="description" content={banner.title} key={banner.id} />
          ))
        }
        <title>Shopping Parque da Cidade » Cinema » Sobre</title>
    </Helmet>
    <main>
        {/* Banner Principal */}

        {banner.map(banner => (
            <div className={styles.cinemaBanner} key={banner.id}>
              <div className={styles.cinemaFilter}>
                  <div className={styles.banner}>
                    <img src={`${process.env.REACT_APP_IMAGES_URL}bannersCinema/${banner.file}`} alt={banner.title}  />
                </div>
              </div>
            </div>
        ))}
        <section id="content">
          <div className={styles.content}>
            {content.map(content => (
              <React.Fragment key={content.id}>
                <div>
                  <img src={`${process.env.REACT_APP_IMAGES_URL}cinema/${content.file}`} alt="Cinema" width="100%" className={styles.contentImg} />
                </div>
                <div className={styles.contentBox}>
                  <h2>{content.title}</h2><br />
                  <div dangerouslySetInnerHTML={{ __html: content.text}}></div>
                  <Link to={`/${content.link}`}>{content.btn_title}</Link>
                </div>
              </React.Fragment>
            ))}
          </div>
        </section>
    </main>
   </>
 );
}


  