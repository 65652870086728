
import React, { useState } from 'react';
//import { GrFacebook, GrInstagram, GrLinkedin, GrYoutube } from 'react-icons/gr';
import { GoTriangleDown } from 'react-icons/go';
import { FaStoreAlt } from 'react-icons/fa';

import instagram from '../../assets/topbar_instagram.png';
import facebook from '../../assets/topbar_facebook.png';
import linkedin from '../../assets/topbar_linkedin.png';
import youtube from '../../assets/topbar_youtube.png';

import styles from './styles.module.scss';

export function Topbar() {
  const [ open, setOpen ]  = useState(false);

  return (
    <>
      <div className={styles.topBar}>
        <div className={`${styles.container} container`}>
          <div>
            <ul>
              <li>
                <a href="https://www.instagram.com/_shoppingparquedacidade/" target="_blank" rel="noreferrer" title="Instagram">
                  <img src={instagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/shoppingparquedacidade" target="_blank" rel="noreferrer" title="Facebook">
                  <img src={facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/shopping-parque-da-cidade" target="_blank" rel="noreferrer" title="Linkedin">
                  <img src={linkedin} alt="Linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCB_rkn8eDylbZIn5c7A3OQw" target="_blank" rel="noreferrer" title="Youtube">
                  <img src={youtube} alt="Youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li onMouseEnter={() => setOpen(true)}><FaStoreAlt color="#fff" size={16} className={styles.icone} /> <span>Área do lojista <GoTriangleDown size={16} /></span>
                {(open ? 
                  <ul className={styles.topBarSubmenu} onMouseLeave={() => setOpen(false)}>
                    <li><a href="https://www.easewebservices.com.br">Financeiro</a></li>
                    <li><a href="https://www.intranetmall.com/shoppingparquedacidade">IntranetMall</a></li>
                  </ul>
                  : '' 
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}





