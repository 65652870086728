import React, { useState } from 'react';

import { FaCheck } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { GoMailRead } from 'react-icons/go';

import styles from './styles.module.scss';

export function ModalNewsletter(props) {
    const [email, setEmail] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);

    async function AcceptNewsletter(e) {
        e.preventDefault();

        try {

            setSuccessMsg(true);
            setEmail('');

            setTimeout(function () { setSuccessMsg(false); }, 3000);

        } catch (error) {
            alert(error);
            //alert('Não foi possível cadastrar seu email, tente novamente mais tarde.');
        }
    }

    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modal}>
                    <GrClose size={30} color="#63a36a" className={styles.modalIcon} onClick={props.close} />
                    <div className={styles.NewsContent}>
                        <GoMailRead size={80} color="#63a36a" />
                        <form>
                            <input type="mail" placeholder="Digite o seu e-mail" onChange={(e) => setEmail(e.target.value)} value={email} />
                            <button onClick={AcceptNewsletter}>Cadastrar</button>
                        </form>
                        {successMsg && <div className={styles.sucesso}><FaCheck color="#63a36a" size={25} />&nbsp; Cadastro realizado com sucesso!</div>}
                    </div>

                </div>

            </div>
        </>
    );
}
