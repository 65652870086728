import React, {useState, useEffect, Fragment} from 'react';
import InputMask from 'react-input-mask';
import { AiFillCaretRight } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';

import Helmet from 'react-helmet';

import api from '../../services/api';

//import spotApi from '../../services/spotApi';

import styles from './contato.module.scss';
import { crMallApi } from '../../services/crmall';

export function Contato(){

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [token, setToken] = useState([]);

  const [telefones, setTelefones] = useState([]);
  const [enderecos, setEnderecos] = useState([]);


  //const FormatedTelefone = telefone.replace('-', '');

  useEffect(() => {

    async function loadAccessToken() {

      const response = await crMallApi.post(`${process.env.REACT_APP_TOKEN_URL}`, 'grant_type=client_credentials', {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_AUTHORIZATION_ID}`,
          'Content-Type': 'application/x-form-urlencoded',
        }
      });

      setToken(response.data.access_token);
    }

    loadAccessToken();

  }, []);

  console.log(token)

  const handleInvalid = (event) => {
    event.target.setCustomValidity('Esse campo é obrigatório!');
  }

  async function SendFormSac(e) {
    e.preventDefault();
  
    try {
      let news = newsletter;
      let assuntoId = 0;

      const descricao = `Nome: ${nome} <br> Email: ${email} <br> Telefone: ${telefone} <br> Assunto: ${assunto} <br> Mensagem: ${mensagem} <br> Deseja receber a newsletter: ${news === true ? 'Sim' : 'Não'}`

      if(assunto === 'reclamações'){
        api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CONTATO_NEGATIVE_MAIL}`, {nome, email});
        assuntoId = 3
      }else if(assunto === 'elogios'){
        api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CONTATO_POSITIVE_MAIL}`, {nome, email});
        assuntoId = 1
      }else {
        api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CONTATO_MAIL}`, {nome, email});
        assuntoId = 2
      }

      api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_SAC_MAIL}`, {
        "nome": nome,
        "email": email,
        "telefone": telefone,
        "assunto": assunto,
        "mensagem": mensagem,
        "newsletter": news === true ? 'Sim' : 'Não'
      });


      const data = {
        "caseCategoryID": 14,
        "caseOriginID": 5,
        "description": descricao,
        "status": 1,
        "priority": assuntoId
      }

      await crMallApi.post(`${process.env.REACT_APP_POST_SAC}`, data, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });


      // const FormatedTelefone = telefone.replace('-', '').replace('(', '').replace(')', '').replace(' ', '');
      // const optInNews = newsletter === true ? 'Sim' : 'Não';

      // const data = {
      //   "ticket": {
      //     "channelId": channelId,
      //     "reasonId": assuntoId,
      //     "statusId": 1,
      //     "comment": descricao
      //   }
      // }

      // const data = {
      //   "nome": nome,
      //   "email": email,
      //   "telefone": FormatedTelefone,
      //   "assunto": assunto,
      //   "mensagem": mensagem,
      //   "newsletter": optInNews
      // }

      // await api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_API_CADCONTATO}`, data, {
      //   headers: {
      //    Accept: 'application/json',
      //    'Access-Control-Allow-Origin': '*',
      //    'Access-Control-Allow-Credentials': true,
      //    'Access-Control-Allow-Headers':
      //    'Origin, X-Requeted-With, Content-Type, Accept, Authorization, RBR',
      //    'Content-Type': 'application/json'
      //   }
      // });

      setSuccessMsg(true);

      setNome('');
      setEmail('');
      setTelefone('');
      setAssunto('');
      setMensagem('');

      setTimeout(function(){ setSuccessMsg(false); }, 3000);

    } catch (error) {
      alert('Não foi possível enviar a sua mensagem, tente novamente mais tarde.');
    }
  }

  useEffect(() => {

    async function loadTelefone() {

      const response = await api.get(process.env.REACT_APP_CONTATO);

      setTelefones([response.data[0]]);
    }

    loadTelefone();

  }, []);

  useEffect(() => {

    async function loadEnderecos() {

      const response = await api.get(process.env.REACT_APP_CONTATO);

      setEnderecos([response.data[1]]);
    }

    loadEnderecos();

  }, []);


  return (
    <>
      <Helmet>
        <meta name="description" content="Contato" />
        <title>Shopping Parque da Cidade » Contato</title>
      </Helmet>
      <main>
          <div id={styles.contatoPage}>
            <div className="container">
              <div className={styles.contatoBanner}>
                <div className={styles.bannerMenu}>
                  <ul><li>Home <AiFillCaretRight size={10} /> Contato </li></ul>
                </div>
                <div>
                  <h1>Contato</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id={styles.formContato}>
            <div className="container">
              <div className={styles.formContatoContent}>
                <div>
                  <h1>Entre em contato conosco preenchendo o formulário abaixo:</h1>
                    <form onSubmit={SendFormSac}>
                      <input type="text" name="nome" placeholder="Nome" onChange={e => {setNome(e.target.value); e.target.setCustomValidity('')}} value={nome} onInvalid={handleInvalid} required />
                      <div className={styles.half}>
                        <input type="email" name="email" placeholder="E-mail" onChange={e => {setEmail(e.target.value); e.target.setCustomValidity('')} } value={email} onInvalid={handleInvalid} required />
                        <InputMask type="tel" mask={ telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999' } maskChar={null}  name="telefone" placeholder="Telefone" onChange={e => {setTelefone(e.target.value); e.target.setCustomValidity('')}} value={telefone} onInvalid={handleInvalid} required />
                      </div>
                      <select name="assunto" onChange={e => {setAssunto(e.target.value); e.target.setCustomValidity('')}} value={assunto} onInvalid={handleInvalid} required>
                        <option value="">Assunto</option>
                        <option value="reclamações">Reclamações</option>
                        <option value="elogios">Elogios</option>
                        <option value="informações">Informações</option>
                        <option value="comercial">Comercial</option>
                        <option value="outros">Outros</option>
                      </select>
                      <label htmlFor="mensagem">Mensagem</label>
                      <textarea name="mensagem" onChange={e => {setMensagem(e.target.value); e.target.setCustomValidity('')}} value={mensagem} onInvalid={handleInvalid} required></textarea>
                      <div className={styles.checkbox}>
                        <input type="checkbox" name="newsletter" className={styles.news} value={newsletter} onChange={e => setNewsletter(e.target.checked)} checked={newsletter === true}  /> <span>Desejo receber a newsletter</span>
                      </div>
                      <div className={styles.msg}>
                        <button className={styles.formButton} type="submit">Enviar</button>
                        {successMsg && <div className="sucesso" style={{justifyContent: 'left'}}><FaCheck color="#63a36a" size={25} />&nbsp; Mensagem enviada com sucesso!</div>}
                      </div> 
                    </form>
                </div>
                <div className={styles.line}><span></span></div>
                <div className={styles.contatoInfo}>
                  {telefones.map(telefone => (
                    <Fragment key={telefone.id}>
                      <strong>{telefone.title}</strong><br />
                      {telefone.text.replace(/<[^>]*>/g, '')}
                    </Fragment>
                  ))}
                  <br /><br />
                  {enderecos.map(endereco => (
                    <Fragment key={endereco.id}>
                      <strong>{endereco.title}</strong><br />
                      <div dangerouslySetInnerHTML={{ __html: endereco.text}}></div>
                    </Fragment>
                  ))}
                  <br /><br />
                  <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.4075367987775!2d-46.70779008458155!3d-23.625571884651087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5737126368f5%3A0x3b9e9f476725f94b!2sShopping%20Parque%20da%20Cidade!5e0!3m2!1spt-BR!2sbr!4v1606333935181!5m2!1spt-BR!2sbr" width="600" height="450"></iframe>
                </div>
              </div>
            </div>
          </section>
      </main>
    </>
  );
}

export default Contato;