import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import { ModalLRB } from '../../components/ModalLRB';

import api from '../../services/api';

import styles from './lojas.module.scss';

export function Lojas() {

  const [banner, setBanner] = useState([]);
  const [lojas, setLojas] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showLoja, setShowLoja] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const folder = "lojas";

  useEffect(() => {

    async function loadBanner() {

      const response = await api.get(process.env.REACT_APP_BANNER_LOJA_PAGE);

      setBanner(response.data);
    }

    loadBanner();
  }, []);

  useEffect(() => {

    async function loadLojas() {

      const response = await api.get(process.env.REACT_APP_LOJAS);

      setLojas(response.data);
    }

    loadLojas();
  }, []);


  useEffect(() => {

    async function loadCategories() {

      const response = await api.get(process.env.REACT_APP_LOJAS_SEGMENTOS);

      setCategories(response.data);

    }

    loadCategories();
  }, []);


  function handleShowMoreLojas() {
    setShowLoja(moreLojas => moreLojas + 4);
  }

  function handleShowLojasByCat(e) {
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowLoja(false);
    e.preventDefault();
  }

  function handleModal(e, loja) {
    setModalContent(loja);
    setModal(true);
  }

  useEffect(() => {
    const suggestions = [];
    lojas.forEach(function (loja) {
      suggestions.push(loja.name);
    });
    setOptions(suggestions);
  }, [lojas]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchLojaByName = lojas.filter(loja => loja.name === searchInput);
  const searchLojaByCat = lojas.filter(loja => loja.category === searchCat || loja.subcategory === searchCat || loja.subcategory2 === searchCat);

  return (
    <>
      <Helmet>
        <title>Shopping Parque da Cidade » Lojas</title>
        <meta name="description" content="Lojas" />
      </Helmet>
      <main>
        {modalContent && (<ModalLRB open={modal} close={() => setModal(false)} fields={modalContent} folder={folder} />)}
        {/* Banner Principal */}

        {banner.map(banner => (
          <div className={styles.lojasBanner} key={banner.id}>
            <div className={styles.lojasFilter}>

              <div className={styles.banner}>
                <img src={`${process.env.REACT_APP_IMAGES_URL}bannersLoja/${banner.file}`} alt={banner.title} />
              </div>

            </div>
          </div>
        ))}

        <section id={styles.todasLojas}>
          <div className="container">
            <form>
              <input
                type="text"
                name="loja"
                onChange={(e) => setSearchInput(e.target.value)}
                onClick={() => setDisplay(!display)}
                value={searchInput}
                placeholder="Encontrar loja"
              />
              <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                <option value="categoria">Categoria</option>
                {categories.map(category => (
                  <option key={category.id} value={category.name}>{category.name}</option>
                ))}
              </select>
              <button className={styles.formButton} onClick={handleShowLojasByCat}>Buscar</button>
              {display && (
                <div className={display ? `${styles.autocomplete}` : "autocomplete"}>
                  {options.filter(nome => nome.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value, index) => {
                    return (
                      <div key={index} data={index} onClick={() => resultSearch(value)}>
                        <span>{value}</span>
                      </div>
                    );
                  })}
                </div>
              )
              }
            </form>
            <div className={styles.lojasLoadContent}>
              {!searchInput ? (
                lojas.slice(0, showLoja).map(loja => (
                  <div id={loja.id} key={loja.id} onClick={(e) => handleModal(e, loja)}>
                    <div className={styles.logo_loja}>
                      <img src={`${process.env.REACT_APP_IMAGES_URL}lojas/${loja.file}`} alt={loja.name} width="100%" />
                    </div>
                    <p>{loja.name}</p>
                    <span>{loja.category}</span>
                  </div>
                ))
              ) :
                (
                  searchLojaByName.map(loja => (
                    <div key={loja.id} id={loja.id} onClick={(e) => handleModal(e, loja)}>
                      <div className={styles.logo_loja}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}lojas/${loja.file}`} alt={loja.name} width="100%" />
                      </div>
                      <p>{loja.name}</p>
                      <span>{loja.category}</span>
                    </div>
                  ))
                )
              }
              {catDisplay && (
                searchLojaByCat.map(loja => (
                  <div key={loja.id} id={loja.id} onClick={(e) => handleModal(e, loja)}>
                    <div className={styles.logo_loja}>
                      <img src={`${process.env.REACT_APP_IMAGES_URL}lojas/${loja.file}`} alt={loja.name} width="100%" />
                    </div>
                    <p>{loja.name}</p>
                    <span>{loja.category}</span>
                  </div>
                ))
              )
              }
            </div>
            {showLoja >= lojas.length || !showLoja || searchInput ? '' : <button className={styles.formButton} onClick={handleShowMoreLojas}>Carregar mais</button>}
          </div>
        </section>
      </main>
    </>
  );
}
