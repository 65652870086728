import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import { Filmes } from '../../components/Filmes';

import api from '../../services/api';

import styles from '../Cinema/cinema.module.scss';

export function CinemaProg(){

  const [banner, setBanner] = useState([]);

  useEffect(() => {

    async function loadBanner() {

      const response = await api.get(process.env.REACT_APP_BANNER_CINEMA_PROGRAMACAO);

      setBanner(response.data);    
    }

    loadBanner();
  }, []);

  return (
    <>
      <Helmet>
        <title>Shopping Parque da Cidade » Cinema » Programação</title>
        <meta name="description" content="Confira os filmes em cartaz" />
      </Helmet>
      <main>
        {/* Banner Principal */}

        {banner.map(banner => (
          <div className={styles.cinemaBanner} key={banner.id}>
            <div className={styles.cinemaFilter}>
              <div className={styles.banner}>
                <img src={`${process.env.REACT_APP_IMAGES_URL}bannersCinema/${banner.file}`} alt={banner.title}  />
              </div>
            </div>
          </div>
        ))}
        <Filmes />
      </main>
   </>
 );
}

export default CinemaProg;