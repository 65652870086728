import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {decode} from 'html-entities';

import { Helmet } from 'react-helmet';

import { SlideHome } from '../../components/SlideHome';
import { ModalNewsletter } from '../../components/ModalNewsletter';

import { GrMail } from 'react-icons/gr';

import api from '../../services/api';

import styles from './home.module.scss';


export function Home(){
  const [secondary, setSecondary] = useState([]);
  const [cinema, setCinema] = useState([]);
  const [destaques, setDestaques] = useState([]);
  const [lojas, setLojas] = useState([]);
  const [mini, setMini] = useState([]);
  const [modal, setModal] = useState(false);

  function handleModal(){
    setModal(true);
  }

  useEffect(() => {

    async function loadBannersSecondary() {

      const response = await api.get(process.env.REACT_APP_BANNER_SECONDARY_URL);

      setSecondary([response.data]);
    }

    loadBannersSecondary();
  }, []);

  useEffect(() => {

    async function loadBannersCinema() {

      const response = await api.get(process.env.REACT_APP_BANNER_CINEMA_URL);

      setCinema([response.data]);
    }

    loadBannersCinema();

  }, []);

  useEffect(() => { 

    async function loadBannersDestaques() {

      const response = await api.get(process.env.REACT_APP_DESTAQUES_URL);

      setDestaques(response.data);
    }

    loadBannersDestaques();

  }, []);

  useEffect(() => { 

    async function loadBannersLoja() {

      const response = await api.get(process.env.REACT_APP_BANNER_LOJA_URL);

      setLojas([response.data]);  
    }

    loadBannersLoja();

  }, []);

  useEffect(() => { 
    
    async function loadBannersMini() {

      const response = await api.get(process.env.REACT_APP_BANNER_MINI_URL);

      setMini(response.data);  
    }

    loadBannersMini();

  }, []);


  return (
    <>
      <Helmet>
        <meta name="description" content="Shopping Parque da Cidade » O 1º Life Center do Brasil" />
        <title>Shopping Parque da Cidade » O 1º Life Center do Brasil</title>
      </Helmet>
      <main>
        <div className={styles.SEO}>
          <h1>No Shopping Parque da Cidade você encontra</h1>
          <h2>Lojas</h2>
          <h3>Cinema</h3>
          <h4>Eventos</h4>
          <h5>Serviços</h5>
          <h6>Horário de Funcionamento</h6>
        </div>
        {modal && (<ModalNewsletter open={modal} close={() => setModal(false)} />)}
        <SlideHome />
        <section id="life-center">
          {secondary[0] === null ? '' : secondary.map(banner => (
            <div className={styles.lifeCenterContent} key={banner.id}>
              <div>
                <img src={`${process.env.REACT_APP_IMAGES_URL}/banners/${banner.file}`} alt={banner.title} width="100%" />
              </div>
              <div className={styles.content}>
                <h2>{banner.title}</h2>
                <p>{decode(banner.text.replace(/<[^>]*>/g, ''))}</p>
                <Link to={`/${banner.link}`}>{banner.btn_title}</Link>
              </div>
            </div>
          ))}
        </section>
        <section id={styles.cinema}>
          {cinema[0] === null ? '' : cinema.map(banner => (
            <div className={styles.cinemaContent} key={banner.id} style={{backgroundImage: `url('${process.env.REACT_APP_IMAGES_URL}banners/${banner.file}')`, backgroundPosition: 'center bottom'} }>
              <div className={styles.filter}>
                <div className={styles.filmes}>
                  <span>Kinoplex</span>
                  <h1>{banner.title}</h1>
                  <p>{decode(banner.text.replace(/<[^>]*>/g, ''))}</p>
                  <Link to={`/${banner.link}`}>{banner.btn_title}</Link>
                </div>
              </div>
            </div>
          ))}
        </section>
        <section id="novidades">
          <div className={styles.novidadesContent}>
            {destaques[0] === null ? '' : destaques.map(banner => (
              <div key={banner.id} style={{backgroundImage:`url('${process.env.REACT_APP_IMAGES_URL}banners/${banner.file}')`}}>
                <div className={styles.novidadesFilter}>
                  <div className={styles.card}>
                    <span>Novidades</span>
                    <h1>{banner.title}</h1>
                    <Link to={`/${banner.link}`}>{banner.btn_title}</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section id={styles.lojas}>
          {lojas[0] === null ? '' : lojas.map(banner => (
            <div className={styles.lojasContent} key={banner.id} style={{backgroundImage:`url('${process.env.REACT_APP_IMAGES_URL}banners/${banner.file}')`}}>
              <div className={styles.lojasFilter}>
                <div className={styles.lojas}>
                  <h1>{banner.title}</h1>
                  <p>{decode(banner.text.replace(/<[^>]*>/g, ''))}</p>
                  <Link to={`/${banner.link}`}>{banner.btn_title}</Link>
                </div>
              </div>
            </div>
          ))}
        </section>
        <section id="diferenciais">
          <div className={styles.diferenciaisContent}>
            {mini[0] === null ? '' : mini.map(banner => (
              <div key={banner.id} style={{backgroundImage:`url('${process.env.REACT_APP_IMAGES_URL}banners/${banner.file}')`}}>
                <div className={styles.diferenciaisFilter}>
                  <div className={styles.card}>
                    <Link to={`/${banner.link}`}>
                      <h1>{banner.title}</h1>
                      <p>{decode(banner.text.replace(/<[^>]*>/g, ''))}</p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section id={styles.newsletter}>
          <div className="container">
            <div className={styles.newsletterContent}>
              <GrMail color="#6ba968" size={30} className={styles.newsIcone} />
              <p>Receba as novidades do Parque da Cidade. <br /> Cadastre-se e seja um dos primeiros a saber de todas as 
                novidades e ofertas.</p>
                <button onClick={handleModal}>Cadastrar</button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
