import { useEffect, useState } from 'react';
import styles from  './styles.module.scss';

export function CookiesConsent() {

    const [show, setShow] = useState(true);

    const cookie = localStorage.getItem('spdc_consent');


    useEffect(() => {
      
        if(cookie){
            setShow(false);
        }

    }, [cookie]);
    

    function acceptCookies() {
        localStorage.setItem('spdc_consent', true);  
        setShow(false);
    }

    return (
        <>
            <div className={styles.boxCookiesContainer}>
                <div className={cookie !== null && show === false ? `${styles.boxCookies} ${styles.hide}` : `${styles.boxCookies}`}>
                    <p className={styles.msgCookies}>Ao navegar pelo nosso site coletaremos cookies para diferentes finalidades.<br /> Você poderá sempre se opor ao tratamento de dados conforme a finalidade 
                    nos termos de nossa.<br />
                        <a href="/politica-de-privacidade">Política de cookies</a>. </p>
                        <div className={styles.btns}>
                            <button className={styles.btnCookies} onClick={acceptCookies}> Aceitar!</button>
                        </div>
                </div>
            </div>
        </>
    );
}