import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { FaUser, FaBuilding, FaCheck } from 'react-icons/fa';
import { GrMail, GrClose, GrInternetExplorer } from 'react-icons/gr';
import { IoFingerPrintSharp } from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import { crMallApi } from '../../services/crmall';
import api from '../../services/api';

import cep from '../../services/cep';

import styles from '../ModalLRB/styles.module.scss';

export function ModalPjForm(props) {

    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [numColab, setNumColab] = useState('');
    const [segmento, setSegmento] = useState('');
    const [site, setSite] = useState('');
    const [nomeRH, setNomeRH] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [genero, setGenero] = useState(0);
    const [accepted, setAccepted] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [dataList, setDataList] = useState([]);

    const [options, setOptions] = useState([]);
    const [display, setDisplay] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    

    const handleInvalid = (event) => {
        event.target.setCustomValidity('Esse campo é obrigatório!');
    }

   
    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
            const foundCEP = response.data;
            setEndereco(foundCEP.logradouro);
            setBairro(foundCEP.bairro);
            setCidade(foundCEP.localidade);
            setUF(foundCEP.uf);
        })
            .catch(error => console.log(error))
    }

    async function SearchClientData() {

        const foundCNPJ = await crMallApi.get(`${process.env.REACT_APP_CRMALL_URL}/${process.env.REACT_APP_GET_CLIENT_DATA_URL}${cnpj}`, {
            headers: { 
                'Authorization': `Bearer ${props.token}`
            }
        });

        await foundCNPJ.data.data === false ? setDisplayError(false) : setDisplayError(true);

    }

    useEffect(() => {
        async function loadEmpresas() {

            const response = await crMallApi.get(`${process.env.REACT_APP_SEGMENT_URL}`, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`
                }
            });
    
            setDataList(response.data.data);
           
        }

        loadEmpresas();    
    }, [props.token]);


    useEffect(() => {
        const suggestions = [];
        dataList.forEach(function (segmento) {
          suggestions.push(segmento.name);
        });
        const filteredSegmentos = [...new Set(suggestions)]

        setOptions(filteredSegmentos);
    }, [dataList]);

    dataList.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const resultSearch = item => {
        setSegmento(item);
        setDisplay(false);
    }

    const filtered = options.filter((segmento, index) => options.indexOf(segmento) === index);

    const findSegmento =  filtered.filter(data => data === segmento);

    async function SendFormCadPJ(e) {
        e.preventDefault();

        try {

            const FormatedCNPJ = cnpj.replace('.', '').replace('-', '').replace('.', '').replace('/', '');
            const FormatedCPF = cpf.replace('.', '').replace('-', '').replace('.', '');


            const pjData = {
                "origin": 7,
                "personType": 2,
                "name": nome,
                "nickOrTradeName": nome,
                "documentNumber": cnpj,
                "addresses": [
                    {
                        "zipCode": CEP,
                        "address": endereco,
                        "number": numero,
                        "complement": complemento,   
                        "neighborhood": bairro, 
                        "city": cidade, 
                        "state": uf,
                        "isDefault": true, 
                    }
                ],
                "importCode": numColab,
                "site": site 
            }

            const pfData = {
                "origin": 7,
                "personType": 1,
                "name": nomeRH,
                "documentNumber": cpf,
                "emails": [
                    {
                        "email": email,
                        "type": 2, 
                        "isDefault": true,
                        "IsOptIn": accepted
                    }
                ],
                "phones": [
                    {
                     "phoneNumber": telefone,
                     "type": 2, 
                     "isDefault": true, 
                    }
                ],
                "gender": genero
            }

            const dataSegmento = {
                "name": segmento
            }

            const ClubeLoyalty = {
                "loyaltyID": 1,
                "document": cnpj,
                "processPendingTransaction": false
            }

            const AcceptedData = {
                "source": 5,
                "consentAcceptances": [
                   {
                      "consentAcceptanceId": 1,
                      "isAccepted": true
                   }
                ]
            }

            if(findSegmento.length <= 0) {
                await crMallApi.post(`${process.env.REACT_APP_SEGMENT_URL}`, dataSegmento, {
                    headers: { 
                        'Authorization': `Bearer ${props.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if(accepted === true){
                await crMallApi.post(`${process.env.REACT_APP_POST_CONSENT_URL}/${process.env.REACT_APP_POST_CONSENT_ID}/document/${FormatedCNPJ}`, AcceptedData, {
                    headers: { 
                        'Authorization': `Bearer ${props.token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
            }

            await crMallApi.post(`${process.env.REACT_APP_POST_PERSON_URL}`, pjData, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json'
                }
            });

            await crMallApi.post(`${process.env.REACT_APP_POST_PERSON_URL}`, pfData, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json'
                }
            });

            await crMallApi.post(`${process.env.REACT_APP_POST_RELATION_PERSON}/${FormatedCPF}/${FormatedCNPJ}/${process.env.REACT_APP_POST_RELATION_ID}`, null, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            });

            await crMallApi.post(`${process.env.REACT_APP_POST_RELATION_LOYALTY}`, ClubeLoyalty, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json'
                }
            });

           api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CLUBEDOPARQUE_PJMAIL}`, { nome, email });

            setSuccessMsg(true);

            setNome('');
            setCnpj('');
            setCEP('');
            setEndereco('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUF('');
            setNumColab('');
            setSegmento('');
            setSite('');
            setNomeRH('');
            setCpf('');
            setEmail('');
            setTelefone('');
            setGenero('');


            setTimeout(function () { setSuccessMsg(false); }, 3000);

        } catch (error) {
            //alert(error.response.data.message);
            alert('Não foi possível realizar o seu cadastro no momento, tente novamente mais tarde.');
        }
    }

    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modalContato}>
                    <GrClose size={30} color="#ffffff" className={`${styles.modalIcon} ${styles.close}`} onClick={props.close} />
                    <div className={styles.identificacao}>
                        <h2>Cadastre sua Empresa</h2>
                    </div>
                    <div className={styles.headerForm}>
                        <h2>Informações da Empresa</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className={styles.modalContentContato}>
                        <form onSubmit={SendFormCadPJ}>
                            <div className={styles.mbInput}>
                                <input type="text" name="nome-fantasia" placeholder="*Nome Fantasia" onChange={e => { setNome(e.target.value); e.target.setCustomValidity('')}} value={nome} onInvalid={handleInvalid} required />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={displayError ? `${styles.mbInput} errorCnpj` : `${styles.mbInput}`}>
                                <InputMask mask="99.999.999/9999-99" type="tel" name="cnpj" placeholder="*CNPJ"
                                    onChange={e => {setCnpj(e.target.value); e.target.setCustomValidity('')}} onBlur={SearchClientData} value={cnpj} onInvalid={handleInvalid} required 
                                />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                                {displayError && <div className="errorCnpj"><i>*CNPJ já cadastrado</i></div>}
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={e => {setCEP(e.target.value); e.target.setCustomValidity('')}} onBlur={pesquisaCEP} value={CEP} onInvalid={handleInvalid} required />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="tel" name="numero" placeholder="*Número" onChange={e => {setNumero(e.target.value); e.target.setCustomValidity('')}} value={numero} onInvalid={handleInvalid} required />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="complemento" placeholder="Complemento" onChange={(e) => setComplemento(e.target.value)} value={complemento} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="uf" placeholder="*UF" onChange={(e) => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <select name="numero-colaboradores" onChange={e => {setNumColab(e.target.value); e.target.setCustomValidity('')}} value={numColab} onInvalid={handleInvalid} required>
                                <option value="numero-colaboradores">*Número de Colaboradores</option>
                                <option value="1 a 10 funcionários">1 a 10 funcionários</option>
                                <option value="11 a 50 funcionários">11 a 50 funcionários</option>
                                <option value="51 a 100 funcionários">51 a 100 funcionários</option>
                                <option value="101 a 300 funcionários">101 a 300 funcionários</option>
                                <option value="301 a 500 funcionários">301 a 500 funcionários</option>
                                <option value="501 a 1000 funcionários">501 a 1000 funcionários</option>
                                <option value="Mais de 1000 funcionários">Mais de 1000 funcionários</option>
                            </select>
                            <div className={styles.mbInput}>
                                <label style={{ display: 'flex', top: '10px' }}><FaBuilding color="#dbdbdb" size={18} /></label>
                                <input type="text" name="segmento" placeholder="*Segmento" onChange={e => {setSegmento(e.target.value); e.target.setCustomValidity('')}} value={segmento} onKeyUp={() => setDisplay(!display)} onInvalid={handleInvalid} required />
                                {display && (
                                    <div className={display ? `${styles.empresas}` : "autocomplete"}>
                                        {filtered.filter(name => name.toLowerCase().indexOf(segmento.toLowerCase()) > -1).map((value, index) => {
                                            return (
                                                <div key={index} onClick={() => resultSearch(value)}>
                                                    {value}
                                                </div>
                                            );
                                        })}
                                        
                                    </div>
                                )
                                }
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="site" placeholder="*Site da Empresa" onChange={e => {setSite(e.target.value); e.target.setCustomValidity('')}} value={site} onInvalid={handleInvalid} required />
                                <label><GrInternetExplorer color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.headerForm}>
                                <h2>Contato do RH</h2>
                                <span><i>*preenchimento obrigatórios</i></span>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="nome" placeholder="*Nome" onChange={e => {setNomeRH(e.target.value); e.target.setCustomValidity('')}} value={nomeRH} onInvalid={handleInvalid} required />
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF" onChange={e => {setCpf(e.target.value); e.target.setCustomValidity('')}} value={cpf} onInvalid={handleInvalid} required />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="email" name="email" placeholder="*E-mail Profissional" onChange={e => {setEmail(e.target.value); e.target.setCustomValidity('')}} value={email} onInvalid={handleInvalid} required />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask type="tel" mask={telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'} maskChar={null} name="telefone" placeholder="*Telefone Empresa / Celular" onChange={e => {setTelefone(e.target.value); ; e.target.setCustomValidity('')}} value={telefone} onInvalid={handleInvalid} required />
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className={styles.infosInput}><p>(DDD + número)</p></div>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputRadios}`}>
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" defaultChecked={genero === 'F'} value="F" onClick={(e) => setGenero(2)} /> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" defaultChecked={genero === 'M'} value="M" onClick={(e) => setGenero(1)} /> Masculino</div>
                                <div><input type="radio" name="genero" id="nao_informar" defaultChecked={genero === 'O'} value="O" onClick={(e) => setGenero(3)} /> Prefiro não informar</div>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputCheckbox}`}>
                                <div><input type="checkbox" name="accepted" value={accepted} onChange={e => setAccepted(e.target.checked)} checked={accepted === true} /> <p>Ao clicar aqui você concorda em nos fornecer seus dados de forma confidencial e de uso exclusivo, de acordo com a LGPD e conforme a Política de Privacidade.</p></div>
                            </div>
                            <button className={styles.formButtonIdentifica} type="submit">Cadastrar</button>
                        {successMsg && <div className="sucesso"><FaCheck color="#63a36a" size={25} />&nbsp; Cadastro realizado com sucesso!</div>}
                        </form>
                       
                    </div>
                </div>
            </div>
        </>
    );
}

