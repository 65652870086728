import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from './components/Header/ScrollToTop';

import { Header } from './components/Header';
import { Home } from './pages/Home';
import { Lojas } from './pages/Lojas';
import { Gastronomia } from './pages/Gastronomia';
import { Cinema } from './pages/Cinema';
import { CinemaProg } from './pages/CinemaProg';
import { AconteceNoParque } from './pages/AconteceNoParque';
import { TemNoParque } from './pages/TemNoParque';
import { ClubedoParque } from './pages/ClubeDoParque';
import { Beneficios } from './pages/Beneficios';
import { Servicos } from './pages/Servicos';
import { Contato } from './pages/Contato';
import { Institucional } from './pages/Institucional';
import { Footer } from './components/Footer';
import { CookiesConsent } from './components/CookieConsent';

export function Routes(){

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/lojas">
              <Lojas />
            </Route>
            <Route exact path="/alimentacao">
              <Gastronomia />
            </Route>
            <Route exact path="/cinema/sobre">
              <Cinema />
            </Route>
            <Route exact path="/cinema/programacao">
              <CinemaProg />
            </Route>
            <Route exact path="/acontecenoparque/:name">
              <AconteceNoParque />
            </Route>
            <Route exact path="/temnoparque/:name">
              <TemNoParque />
            </Route>
            <Route exact path="/clubedoparque/sobre">
              <ClubedoParque />
            </Route>
            <Route exact path="/clubedoparque/beneficios">
              <Beneficios />
            </Route>
            <Route exact path="/servicos">
              <Servicos />
            </Route>
            <Route exact path="/contato">
              <Contato />
            </Route>
            <Route exact path="/:name">
              <Institucional />
            </Route>

            <Redirect to="/" />
     
            
            
            
            
           
          </Switch>
          <Footer />
          <CookiesConsent />
      </ScrollToTop>
    </BrowserRouter>
  );
}

