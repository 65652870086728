import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { AiFillCalendar } from 'react-icons/ai';
import { FaUser, FaBuilding, FaCheck } from 'react-icons/fa';
import { GrMail, GrClose } from 'react-icons/gr';
import { IoFingerPrintSharp } from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import styles from '../ModalLRB/styles.module.scss';

import { crMallApi } from '../../services/crmall';
import api from '../../services/api';

import cep from '../../services/cep';

export function ModalPfForm(props) {

    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [dataNasc, setDataNasc] = useState('');
    const [genero, setGenero] = useState(0);
    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    //const [dataList, setDataList] = useState([]);

    //const [options, setOptions] = useState([]);
    const [display, setDisplay] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const handleInvalid = (event) => {
        event.target.setCustomValidity('Esse campo é obrigatório!');
    }
      

    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
            const foundCEP = response.data;
            setEndereco(foundCEP.logradouro);
            setBairro(foundCEP.bairro);
            setCidade(foundCEP.localidade);
            setUF(foundCEP.uf);
        })
            .catch(error => console.log(error))
    }


    async function SearchClientData() {

        const foundCPF = await crMallApi.get(`${process.env.REACT_APP_CRMALL_URL}/${process.env.REACT_APP_GET_CLIENT_DATA_URL}${cpf}`, {
            headers: { 
                'Authorization': `Bearer ${props.token}`
            }
        });

        await foundCPF.data.data === false ? setDisplayError(false) : setDisplayError(true);

    }


    async function SendFormCadPF(e) {
        e.preventDefault();

        try {

            const ano = dataNasc.slice(6, 10);
            const mes = dataNasc.slice(3, 5);
            const dia = dataNasc.slice(0, 2);
            const FormatedCPF = cpf.replace('.', '').replace('-', '').replace('.', '');

            const data = {
                "origin": 7,
                "personType": 1,
                "documentNumber": cpf,
                "name": nome,
                "NickOrTradeName": empresa,
                "phones": [
                    {
                     "phoneNumber": telefone,
                     "type": 1, 
                     "isDefault": true
                    
                    }
                ],
                "emails": [
                    {
                        "email": email,
                        "type": 2, 
                        "isDefault": true,
                        "IsOptIn": accepted
                    }
                ],
                "day": dia,
                "month": mes,
                "year": ano,
                "gender": genero,
                "addresses": [
                    {
                        "zipCode": CEP,
                        "address": endereco,
                        "number": numero,
                        "complement": complemento,   
                        "neighborhood": bairro, 
                        "city": cidade, 
                        "state": uf,
                        "isDefault": true, 
                    }
                ]
            }

            const ClubeLoyalty = {
                "loyaltyID": 1,
                "document": cpf,
                "processPendingTransaction": false
            }


            const AcceptedData = {
                "source": 5,
                "consentAcceptances": [
                   {
                      "consentAcceptanceId": 1,
                      "isAccepted": true
                   }
                ]
            }

            if(accepted === true){
                await crMallApi.post(`${process.env.REACT_APP_POST_CONSENT_URL}/${process.env.REACT_APP_POST_CONSENT_ID}/document/${FormatedCPF}`, AcceptedData, {
                    headers: { 
                        'Authorization': `Bearer ${props.token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
            }

            await crMallApi.post(`${process.env.REACT_APP_POST_PERSON_URL}`, data, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json'
                }
            });

            await crMallApi.post(`${process.env.REACT_APP_POST_RELATION_LOYALTY}`, ClubeLoyalty, {
                headers: { 
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/json'
                }
            });


            api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CLUBEDOPARQUE_PFMAIL}`, { nome, email });

            setSuccessMsg(true);

            setCpf('');
            setNome('');
            setEmpresa('');
            setTelefone('');
            setEmail('');
            setDataNasc('');
            setCEP('');
            setEndereco('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUF('');

            setTimeout(function () { setSuccessMsg(false); }, 3000);

        } catch (error) {
            //alert(error.response.data.message);
            alert('Não foi possível realizar o seu cadastro no momento, tente novamente mais tarde.');
            //console.log(error);
        }
    }


    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modalContato}>
                    <GrClose size={30} color="#ffffff" className={`${styles.modalIcon} ${styles.close}`} onClick={props.close} />
                    <div className={styles.identificacao}>
                        <h2>Cadastro Pessoa Física</h2>
                    </div>
                    <div className={styles.headerForm}>
                        <h2>Informações pessoais</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className={styles.modalContentContato}>
                        <form onSubmit={SendFormCadPF}>
                            <div className={displayError ? `${styles.mbInput} errorCpf` : `${styles.mbInput}`}>
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF"
                                    onChange={e => {setCpf(e.target.value); e.target.setCustomValidity('')}} value={cpf}
                                    onBlur={SearchClientData}
                                    onInvalid={handleInvalid} required
                                />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                                {displayError && <div className="errorCpf"><i>*CPF já cadastrado</i></div>}
                            </div>

                            <div className={styles.mbInput}>
                                <input type="text" name="nome" placeholder="*Nome" onChange={e => {setNome(e.target.value); e.target.setCustomValidity('')}} value={nome} onInvalid={handleInvalid} required/>
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <label style={{ display: 'flex', top: '10px' }}><FaBuilding color="#dbdbdb" size={18} /></label>
                                <input type="text" name="empresa" placeholder="*Empresa, Condomínio Residencial ou Casa" onChange={e => {setEmpresa(e.target.value); e.target.setCustomValidity('')}} value={empresa} onKeyUp={() => setDisplay(!display)} onInvalid={handleInvalid} required />
                                {/* {display && (
                                    <div className={display ? `${styles.empresas}` : "autocomplete"}>
                                        {filtered.filter(name => name.toLowerCase().indexOf(empresa.toLowerCase()) > -1).map((value, index) => {
                                            return (
                                                <div key={index} onClick={() => resultSearch(value)}>
                                                    {value}
                                                </div>
                                            );
                                        })}
                                        
                                    </div>
                                )
                                } */}
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="(99) 99999-9999" type="tel" name="telefone" placeholder="*Telefone Celular" onChange={e => {setTelefone(e.target.value); e.target.setCustomValidity('')}} value={telefone} onInvalid={handleInvalid} required/>
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className={styles.infosInput}><p>(DDD + número)</p></div>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="email" name="email" placeholder="*E-mail" onChange={e => {setEmail(e.target.value); e.target.setCustomValidity('')}} value={email} onInvalid={handleInvalid} required />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99/99/9999" type="tel" name="data_nasc" placeholder="*Data de nascimento" onChange={e => {setDataNasc(e.target.value); e.target.setCustomValidity('')}} value={dataNasc} onInvalid={handleInvalid} required />
                                <label><AiFillCalendar color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputRadios}`}>
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" defaultChecked={genero === 'F'} value="F" onClick={(e) => setGenero(2)} /> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" defaultChecked={genero === 'M'} value="M" onClick={(e) => setGenero(1)} /> Masculino</div>
                                <div><input type="radio" name="genero" id="nao_informar" defaultChecked={genero === 'O'} value="O" onClick={(e) => setGenero(3)} /> Prefiro não informar</div>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={e => {setCEP(e.target.value); e.target.setCustomValidity('')}} onBlur={pesquisaCEP} value={CEP} onInvalid={handleInvalid} required />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={e => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="tel" name="numero" placeholder="*Número" onChange={e => {setNumero(e.target.value); e.target.setCustomValidity('')}} value={numero} onInvalid={handleInvalid} required />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="complemento" placeholder="Complemento" onChange={e => setComplemento(e.target.value)} value={complemento} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={e => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={e => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="uf" placeholder="*UF" onChange={e => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputCheckbox}`}>
                                <div><input type="checkbox" name="accepted" value={accepted} onChange={e => setAccepted(e.target.checked)} checked={accepted === true} /> <p>Ao clicar aqui você concorda em nos fornecer seus dados de forma confidencial e de uso exclusivo, de acordo com a LGPD e conforme a Política de Privacidade.</p></div>
                            </div>
                            <button className={styles.formButtonIdentifica} type="submit">Cadastrar</button>
                            {successMsg && <div className="sucesso"><FaCheck color="#63a36a" size={25} />&nbsp; Cadastro realizado com sucesso!</div>}
                        </form>
                       
                        
                    </div>
                </div>
            </div>
        </>
    );
}
