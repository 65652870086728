import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Helmet from 'react-helmet';

import { Accordion } from '../../components/Accordion';

import styles from './servicos.module.scss';

export function Servicos(){
  return (
    <>
      <Helmet>
        <meta name="description" content="Serviços" />
        <title>Shopping Parque da Cidade » Serviços</title>
      </Helmet>      
      <main>
          <div id={styles.servicosPage}>
            <div className="container">
              <div className={styles.servicosBanner}>
                <div className={styles.bannerMenu}>
                  <ul><li>Home <AiFillCaretRight size={10} /> Serviços </li></ul>
                </div>
                <div>
                  <h1>Serviços</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id="servicos">
            <Accordion />
          </section>
      </main>
    </>
  );
}

