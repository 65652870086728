import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import { ModalLRB } from '../../components/ModalLRB';

import api from '../../services/api';

import styles from './gastronomia.module.scss';

export function Gastronomia(){

  const [banner, setBanner] = useState([]);
  const [restaurantes, setRestaurantes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showRestaurante, setShowRestaurante] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const folder = "restaurantes";

  useEffect(() => {

    async function loadBanner() {

      const response = await api.get(process.env.REACT_APP_BANNER_ALIMENTACAO_PAGE);

      setBanner(response.data);
    }

    loadBanner();
  }, []);

  useEffect(() => {

    async function loadRestaurantes() {

      const response = await api.get(process.env.REACT_APP_ALIMENTACAO);

      setRestaurantes(response.data);
    }

    loadRestaurantes();
  }, []);


  useEffect(() => {

    async function loadCategories() {

        const response = await api.get(process.env.REACT_APP_ALIMENTACAO_SEGMENTOS);

        setCategories(response.data);   
    }

    loadCategories();
  }, []);


  function handleShowMoreRestaurantes() {
    setShowRestaurante(moreRestaurantes => moreRestaurantes + 4);
  }

  function handleShowRestaurantesByCat(e){
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowRestaurante(false);
    e.preventDefault();
  }

  function handleModal(e, restaurante){
    setModalContent(restaurante);
    setModal(true);
  }

  useEffect(() => {
    const suggestions = [];
    restaurantes.forEach(function (restaurante) {
      suggestions.push(restaurante.name);    
    });
    setOptions(suggestions);
  }, [restaurantes]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchRestauranteByName = restaurantes.filter(restaurante => restaurante.name === searchInput);
  const searchRestauranteByCat = restaurantes.filter(restaurante => restaurante.category === searchCat || restaurante.subcategory === searchCat || restaurante.subcategory2 === searchCat);

  return (
    <>
      <Helmet>
        <title>Shopping Parque da Cidade » Alimentação</title>
        <meta name="description" content="Praça de Alimentação" />
      </Helmet>
      <main>
          {modalContent && (<ModalLRB open={modal}  close={() => setModal(false)} fields={modalContent} folder={folder} />)}

          {/* Banner Principal */}

          {banner.map(banner => (
              <div id={styles.gastronomiaBanner}  key={banner.id}>
                  <div className={styles.gastronomiaFilter}>
                      <div className={styles.banner}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}bannersGastronomia/${banner.file}`} alt={banner.title}  />
                      </div>
                  </div>
              </div>
          ))}

          <section id={styles.todasGastronomia}>
            <div className="container">
              <form>
                <input 
                  type="text" 
                  name="restaurante"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={() => setDisplay(!display)}
                  value={searchInput}
                  placeholder="Encontrar restaurante" 
                />
                <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                  <option value="categoria">Categoria</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.name}>{category.name}</option>
                  ))}
                </select>
                <button className={styles.formButton} onClick={handleShowRestaurantesByCat}>Buscar</button>
                { display && (
                    <div className={display ? `${styles.autocomplete}`  : "autocomplete"}>
                      {options.filter(nome => nome.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value,index) => {
                        return (
                          <div key={index} data={index} onClick={() => resultSearch(value)}>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                    </div> 
                  )
                }                
              </form>
              <div className={styles.gastronomiaLoadContent}>
                {!searchInput ? (
                    restaurantes.slice(0, showRestaurante).map((restaurante, index) => (
                      <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                        <div className={styles.logo_restaurante}>
                          <img src={`${process.env.REACT_APP_IMAGES_URL}restaurantes/${restaurante.file}`} alt={restaurante.name} width="100%" />
                        </div>
                        <p>{restaurante.name}</p>
                        <span>{restaurante.category}</span>
                      </div>
                    ))
                  ) :
                  (
                    searchRestauranteByName.map((restaurante, index) => (
                        <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                          <div className={styles.logo_restaurante}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}restaurantes/${restaurante.file}`} alt={restaurante.name} width="100%" />
                          </div>
                          <p>{restaurante.name}</p>
                          <span>{restaurante.category}</span>
                        </div>
                    ))
                  ) 
                }
                {catDisplay && (
                    searchRestauranteByCat.map((restaurante, index) => (
                      <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                        <div className={styles.logo_restaurante}>
                          <img src={`${process.env.REACT_APP_IMAGES_URL}restaurantes/${restaurante.file}`} alt={restaurante.name} width="100%" />
                        </div>
                        <p>{restaurante.name}</p>
                        <span>{restaurante.category}</span>
                      </div>
                    ))   
                  )
                }
              </div>
              {showRestaurante >= restaurantes.length || !showRestaurante || searchInput ? '' : <button className={styles.formButton} onClick={handleShowMoreRestaurantes}>Carregar mais</button>}
            </div>
          </section>
      </main>
    </>
  );
}
