import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { GoTriangleDown } from 'react-icons/go';

import { Topbar } from '../Topbar';

import logo from '../../assets/logo.png';

import api from '../../services/api';

import instagram from '../../assets/headerMobile_instagram.png';
import facebook from '../../assets/headerMobile_facebook.png';
import linkedin from '../../assets/headerMobile_linkedin.png';
import youtube from '../../assets/headerMobile_youtube.png';

import '../../styles/menu.scss';
import styles from './styles.module.scss';

export function Header() {

  const [ open, setOpen ]  = useState(false);
  const [ open2, setOpen2 ] = useState(false);
  const [ open3, setOpen3 ] = useState(false);
  const [ open4, setOpen4 ] = useState(false);
  const [ open5, setOpen5 ] = useState(false);
  const [ openMobile, setOpenMobile ] = useState(false);
  const handleSub1 = () => { setOpen(!open); setOpen2(false); setOpen3(false); setOpen4(false); setOpen5(false);};
  const handleSub2 = () => { setOpen2(!open2); setOpen(false); setOpen3(false); setOpen4(false); setOpen5(false);};
  const handleSub3 = () => { setOpen3(!open3); setOpen(false); setOpen2(false); setOpen4(false); setOpen5(false);};
  const handleSub4 = () => { setOpen4(!open4); setOpen(false); setOpen2(false); setOpen3(false); setOpen5(false);};
  const handleSub5 = () => { setOpen5(!open5); }
  const handleRemoveSubs = () => {setOpen(false); setOpen2(false); setOpen3(false); setOpen4(false); setOpen5(false);};

  const [menuAconteceNoParque, setMenuAconteceNoParque] = useState([]);
  const [menuTemNoParque, setMenuTemNoParque] = useState([]);

  useEffect(() => {

    async function loadMenuAconteceNoParque() {

      const response = await api.get(process.env.REACT_APP_ACONTECENOPARQUE_HEADER);

      setMenuAconteceNoParque(response.data);
    }

    loadMenuAconteceNoParque();
  }, []);

  useEffect(() => {

    async function loadMenuTemNoParque() {

      const response = await api.get(process.env.REACT_APP_TEMNOPARQUE_HEADER);

      setMenuTemNoParque(response.data);
    }

    loadMenuTemNoParque();
  }, []);

  const handleAconteceNoParque = () => {
    handleSub2(); 
    setOpenMobile(!openMobile);
  }

  const handleTemNoParque = () => {
    handleSub3(); 
    setOpenMobile(!openMobile);
  }

  const handleMobileMenu = () => {
    setOpenMobile(!openMobile);
  }
  
  return (
    <div className={styles.Header}>
      <Topbar />
      <header className={openMobile ? `${styles.headerMobileActive}` : `${styles.headerMobileInative}`}>
        <div className={styles.mobile}>
          <button type="button" onClick={() => setOpenMobile(!openMobile)} className={openMobile ? "hamburger hamburger--stand is-active" : "hamburger hamburger--spin"}>
            <span className="hamburger-box">
            <span className="hamburger-inner"></span>
            </span>
          </button>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="Shopping Parque da Cidade" width="100%" />
            </Link>
          </div>
        </div>
        <hr/>
        <div className="container">
          <ul>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/lojas" onClick={handleMobileMenu}>Lojas</Link></li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/alimentacao" onClick={handleMobileMenu}>Alimentação</Link></li>
            <li onMouseEnter={() => handleSub1()}><span>Cinema <GoTriangleDown size={16} /></span> 
              {(open ? 
                <>
                  <div className={styles.onlymobile}>
                    <ul className={styles.submenu}>
                      <li><Link to="/cinema/sobre" onClick={handleMobileMenu}>Sobre</Link></li>
                      <li><Link to="/cinema/programacao" onClick={handleMobileMenu}>Programação</Link></li>
                    </ul>
                  </div>
                  <ul className={styles.submenudesk}>
                    <li><Link to="/cinema/sobre" onClick={() => handleRemoveSubs()}>Sobre</Link></li>
                    <li><Link to="/cinema/programacao" onClick={() => handleRemoveSubs()}>Programação</Link></li>
                  </ul>
                </>
                 : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub2()}><span>Acontece no parque <GoTriangleDown size={16} /></span> 
              {(open2 ? 
                <>
                  <div className={styles.onlymobile}>
                    <ul className={styles.submenu}>
                      {menuAconteceNoParque.map(menu => (
                        <li key={menu.id}>
                          <Link to={`/acontecenoparque/${menu.slug}`} title={menu.page} onClick={handleAconteceNoParque}>{menu.page}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <ul className={styles.submenudesk}>
                    {menuAconteceNoParque.map(menu => (
                      <li key={menu.id}>
                        <Link to={`/acontecenoparque/${menu.slug}`} title={menu.page} onClick={() => setOpen2(!open2)}>{menu.page}</Link>
                      </li>
                    ))}
                  </ul>
                </> 
                : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub3()}><span>Tem no parque <GoTriangleDown size={16} /></span> 
              {(open3 ? 
                <>
                  <div className={styles.onlymobile}>
                    <ul className={styles.submenu}>
                      {menuTemNoParque.map(menu => (
                        <li key={menu.id}>
                          <Link to={`/temnoparque/${menu.slug}`} title={menu.page} onClick={handleTemNoParque}>{menu.page}</Link>
                        </li>
                      ))}
                    </ul>
                  </div> 
                  <ul className={styles.submenudesk}>
                    {menuTemNoParque.map(menu => (
                        <li key={menu.id}>
                          <Link to={`/temnoparque/${menu.slug}`} title={menu.page} onClick={() => setOpen3(!open3)}>{menu.page}</Link>
                        </li>
                      ))}
                  </ul>
                </> 
                : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub4()}><span>Clube do parque <GoTriangleDown size={16} /></span> 
              {(open4 ? 
                <>
                  <div className={styles.onlymobile}>
                    <ul className={styles.submenu}>
                      <li><Link to="/clubedoparque/sobre" onClick={handleMobileMenu}>Sobre</Link></li>
                      <li><Link to="/clubedoparque/beneficios" onClick={handleMobileMenu}>Benefícios</Link></li>
                    </ul>
                  </div>
                  <ul className={styles.submenudesk}>
                    <li><Link to="/clubedoparque/sobre" onClick={() => handleRemoveSubs()}>Sobre</Link></li>
                    <li><Link to="/clubedoparque/beneficios" onClick={() => handleRemoveSubs()}>Benefícios</Link></li>
                  </ul>
                </> : '' 
              )}
            </li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/servicos" onClick={handleMobileMenu}>Serviços</Link></li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/contato" onClick={handleMobileMenu}>Contato</Link></li>
            <li className={styles.onlymobile} onMouseEnter={() => handleSub5()}><span>Área do lojista <GoTriangleDown size={16} /></span> 
              {(open5 ? 
                <>
                  <div className={styles.onlymobile}>
                    <ul className={styles.submenu}>
                      <li><a href="https://www.easewebservices.com.br">Financeiro</a></li>
                      <li><a href="https://www.intranetmall.com/shoppingparquedacidade">IntranetMall</a></li>
                    </ul>
                  </div>
                </> : ''
              )} 
            </li>
            <li className={styles.onlymobile}>
              <a href="https://www.instagram.com/_shoppingparquedacidade/" target="_blank" rel="noreferrer" title="Instagram">
                <img src={instagram} alt="Instagram" />
              </a>
              <a href="https://www.facebook.com/shoppingparquedacidade" target="_blank" rel="noreferrer" title="Facebook">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="https://www.linkedin.com/company/shopping-parque-da-cidade" target="_blank" rel="noreferrer" title="Linkedin">
                <img src={linkedin} alt="Linkedin" />
              </a>
              <a href="https://www.youtube.com/channel/UCB_rkn8eDylbZIn5c7A3OQw" target="_blank" rel="noreferrer" title="Youtube">
                <img src={youtube} alt="Youtube" />
              </a>
            </li>
          </ul>
        </div>
        <hr/>
      </header>
    </div>
  );
}
