import React, { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import api from '../../services/api';

import styles from './styles.module.scss';


export function Accordion(){

  const [servicos, setServicos] = useState([]);
  const [open, setOpen] = useState("");
  const [arrow, setArrow] = useState(false);

  useEffect(() => {

    async function loadServicos() {

      const response = await api.get(process.env.REACT_APP_SERVICOS);

      setServicos(response.data);
    }

    loadServicos();
  }, []);

  const toggle = (index) => {
    setOpen(open === index ? "" : index);
    setArrow(arrow === index ? "" : index);
  };


  return (
    <>
      <div className="container">
        <ul className={styles.accordionList}>
          {
            servicos.map((item, index) => (
              <React.Fragment key={index}>
                <li  id={index} onClick={() => toggle(index)}>
                  <span><img src={`${process.env.REACT_APP_IMAGES_URL}servicos/${item.file}`} alt={item.title}/></span> <b>{item.title}</b> {arrow === index ? <IoIosArrowUp size={30} color="#485860" className={styles.iconAccordion} /> : <IoIosArrowDown size={30} color="#485860" className={styles.iconAccordion} />}
                </li>
                <div className={open === index ? `${styles.open}` : `${styles.close}`} dangerouslySetInnerHTML={{__html: `${item.text}` }} />
              </React.Fragment>
            ))
          }
        </ul>
      </div>
    </>
  );
}
