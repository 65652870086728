import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import Helmet from 'react-helmet';

import api from '../../services/api';

import styles from './institucional.module.scss';

export function Institucional(){

  const { name } = useParams();
  let history = useHistory();

  const [Institucional, setInstitucional] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {

    async function loadContent() {

      const response = await api.get(`${process.env.REACT_APP_INSTITUCIONAL}/${name}`);

      const data = response.data;
      
      if(!data){
        history.push("/");
      }else {
        setInstitucional([response.data]);   
        setTitle(response.data.page);
      }

      
    }

    loadContent();
  }, [name, history]);


  return (
    <>
      <Helmet>
        <meta name="description" content="Institucional" />
        <title>Shopping Parque da Cidade » {title}</title>
      </Helmet>
      <main>
        {Institucional.map(content => (
          <div key={content.id}>
            <div className={styles.institucionalBanner}>
              <div className={styles.institucionalFilter}>
                <div className={styles.banner}>
                <img src={`${process.env.REACT_APP_IMAGES_URL}bannersInstitucional/${content.BannerID.file}`} alt={content.page} />
                </div>
              </div>
            </div>
            <section id={styles.informativo}>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: content.text}}></div>
              </div>
            </section>
          </div>
        ))}
      </main>
    </>
  );
}