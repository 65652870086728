import React, { useState, useEffect } from 'react';

import { AiFillCaretRight } from 'react-icons/ai';

import Helmet from 'react-helmet';

import { ModalLRB } from '../../components/ModalLRB';

import api from '../../services/api';

import styles from './beneficios.module.scss';


export function Beneficios(){

  const [clubes, setClubes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showClube, setShowClube] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const folder = "clubedoparque";

  useEffect(() => {

    async function loadBeneficios() {

      const response = await api.get(process.env.REACT_APP_CLUBEDOPARQUE_BENEFICIOS);

      setClubes(response.data);
    }

    loadBeneficios();
  }, []);

  useEffect(() => {

    async function loadCategories() {

        const response = await api.get(process.env.REACT_APP_CLUBEDOPARQUE_SEGMENTOS);

        setCategories(response.data);   
    }

    loadCategories();
  }, []);

  function handleShowMoreClubes() {
    setShowClube(moreClubes => moreClubes + 4);
  }

  function handleShowClubesByCat(e){
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowClube(false);
    e.preventDefault();
  }

  function handleModal(e, clube){
    setModalContent(clube);
    setModal(true);
  }

  useEffect(() => {
    const suggestions = [];
    clubes.forEach(function (clube) {
      suggestions.push(clube.name);    
    });
    setOptions(suggestions);
  }, [clubes]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchClubeByName = clubes.filter(clube => clube.name === searchInput);
  const searchClubeByCat = clubes.filter(clube => clube.category === searchCat || clube.subcategory === searchCat || clube.subcategory2 === searchCat);

  return (
    <>
      <Helmet>
        <meta name="description" content="Lojas Participantes" />
        <title>Shopping Parque da Cidade » Clube do Parque » Benefícios</title>
      </Helmet>
      <main>
          {modalContent && (<ModalLRB open={modal}  close={() => setModal(false)} fields={modalContent} folder={folder} />)}
          <div id={styles.beneficiosPage}>
            <div className="container">
              <div className={styles.beneficiosBanner}>
                <div className={styles.bannerMenu}>
                  <ul><li>Home <AiFillCaretRight size={10} /> Clube do Parque <AiFillCaretRight size={10} /> Lojas Participantes</li></ul>
                </div>
                <div>
                  <h1>Lojas Participantes</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id={styles.todosBeneficios}>
            <div className="container">
              <form>
                <input 
                  type="text" 
                  name="beneficio"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={() => setDisplay(!display)}
                  value={searchInput}
                  placeholder="Buscar benefício" 
                />
                <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                  <option value="categoria">Categoria</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.name}>{category.name}</option>
                  ))}
                </select>
                <button className={styles.formButton} onClick={handleShowClubesByCat}>Buscar</button>
                {display && (
                    <div className={display ? `${styles.autocomplete}`  : "autocomplete"}>
                      {options.filter(name => name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value,index) => {
                        return (
                          <React.Fragment key={index}>
                            <div data={index} onClick={() => resultSearch(value)}>
                              <span>{value}</span>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div> 
                  )
                }            
              </form>
              <div className={styles.beneficiosLoadContent}>
              {!searchInput ? (
                    clubes.slice(0, showClube).map((clube, index) => (
                      <React.Fragment key={index}>
                        <div id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className={styles.logoClube}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}clubedoparque/${clube.file}`} alt={clube.name} width="100%" />
                          </div>
                          <p>{clube.name}</p>
                          <span>{clube.category}</span>
                        </div>
                      </React.Fragment>
                    ))
                  ) :
                  (
                    searchClubeByName.map((clube, index) => (
                      <React.Fragment key={index}>
                        <div id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className={styles.logoClube}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}clubedoparque/${clube.file}`} alt={clube.name} width="100%" />
                          </div>
                          <p>{clube.name}</p>
                          <span>{clube.category}</span>
                        </div>
                      </React.Fragment>
                    ))
                  ) 
                }
                {catDisplay && (
                    searchClubeByCat.map((clube, index) => (
                      <React.Fragment key={index}>
                        <div id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className={styles.logoClube}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}clubedoparque/${clube.file}`} alt={clube.name} width="100%" />
                          </div>
                          <p>{clube.name}</p>
                          <span>{clube.category}</span>
                        </div>
                      </React.Fragment>
                    ))   
                  )
                }
              </div>
              {showClube >= clubes.length || !showClube || searchInput ? '' : <button className={styles.formButton} onClick={handleShowMoreClubes}>Carregar mais</button>}
              
            </div>
          </section>
      </main>
    </>
  );
}
