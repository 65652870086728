import { FaInstagram, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import {decode} from 'html-entities';

import styles from './styles.module.scss';

export function ModalLRB(props) { 
    return (
        <>
            <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modal}>
                  <GrClose size={30} color="#485860" className={styles.modalIcon} onClick={props.close} />
                    <div className={styles.modalContent}>
                      <div className={styles.logo}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}${props.folder}/${props.fields.file}`} alt={props.fields.name} />
                      </div>
                      <div className={styles.info}>
                        <h1>{props.fields.name}</h1>
                        <p>
                          {props.fields.category && (
                            `${props.fields.category}`
                            )
                          }
                          {props.fields.subcategory && (
                             `, ${props.fields.subcategory}`
                            )
                          }

                          {props.fields.subcategory2 && (
                             `, ${props.fields.subcategory2}`
                            )
                          }
                        </p>
                        <br />
                        <p className={styles.categoria}>
                          {decode(props.fields.text.replace(/<[^>]*>/g, ''))}
                          
                          {props.fields.bio && (
                            <>
                              <br /><br />
                              Benefício: {decode(props.fields.bio.replace(/<[^>]*>/g, ''))}
                            </>
                          )}
                        </p>
                        <br />
                        <strong>{props.fields.floor}</strong>
                        <br /><br />
                        <p>{props.fields.phone}</p>
                        <br />
                        <a href={props.fields.site} target="_blank" rel="noopener noreferrer">{props.fields.site}</a>
                        <br /><br />
                        <ul className={styles.modalRedesSociais}>
                          {props.fields.instagram !== '' && (
                            <li>
                              <a href={props.fields.instagram} target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {props.fields.facebook !== '' && (
                            <li>
                              <a href={props.fields.facebook} target="_blank" rel="noopener noreferrer">
                                <FaFacebookSquare size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {props.fields.whatsapp !== '' && (
                            <li>
                              <a href={`https://api.whatsapp.com/send?phone=${props.fields.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </>
    );
}

