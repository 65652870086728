import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from "react-router-dom";
import { ModalGallery } from '../../components/ModalGallery';

import Helmet from 'react-helmet';  

import api from '../../services/api';

import styles from './TemNoParque.module.scss';
import { Fragment } from 'react/cjs/react.production.min';

export function TemNoParque(){

  const { name } = useParams();
  let history = useHistory();
  const folder = "temnoparquegalerias";


  const [TemNoParque, setTemNoParque] = useState([]);
  const [TemNoParqueDestaques, setTemNoParqueDestaques] = useState([]);
  const [TemNoParqueGalerias, setTemNoParqueGalerias] = useState([]);
  const [title, setTitle] = useState('');
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);


  function handleModal(e, image) {
    setModalContent(image);
    setModal(true);
  }

  useEffect(() => { 
    
    async function loadContent() {

      const response = await api.get(`${process.env.REACT_APP_TEMNOPARQUE_CONTENT}/${name}`);

      const data = response.data;
      
      if(!data){
        history.push("/");
      }else {
        setTemNoParque([response.data]); 
        setTitle(response.data.page);
      }

    }

    loadContent();

  }, [name, history]);


  useEffect(() => { 
    
    async function loadDestaques() {

      const response = await api.get(`${process.env.REACT_APP_TEMNOPARQUE_DESTAQUES}/${name}`);

      setTemNoParqueDestaques(response.data);  

    }

    loadDestaques();

  }, [name]);

  useEffect(() => { 

    setTemNoParqueGalerias([]);
    
    async function loadGaleria() {

      const response = await api.get(`${process.env.REACT_APP_TEMNOPARQUE_GALERIAS}/${name}`);

      setTemNoParqueGalerias([response.data]);  

    }
    
    loadGaleria();

  }, [name]);

  return (
    <>
      <Helmet>
        {
          TemNoParque.map(content => (
            <meta name="description" content={content.BannerID.title} key={content.id} />
          ))
        }
        <title>Shopping Parque da Cidade » Tem no Parque » {title}</title>
      </Helmet>
      <main>
        {modalContent && (<ModalGallery open={modal} close={() => setModal(false)} image={modalContent} folder={folder} />)}
        {TemNoParque.map(content => (
          <div key={content.id}>
            <div className={styles.temNoParqueBanner}>
              <div className={styles.temNoParqueFilter}>
                <div className={styles.banner}>
                  <img src={`${process.env.REACT_APP_IMAGES_URL}bannersTemNoParque/${content.BannerID.file}`} alt={content.page} className={styles.BannerDesk}  />
                </div>
              </div>
            </div>
            <section id={styles.temNoParque}>
              <div className="container">
                <div className={styles.temNoParqueContent}>
                  <div className={styles.temNoParque}>
                    <div dangerouslySetInnerHTML={{ __html: content.text}}></div>
                  </div>
                  <div>
                    <img src={`${process.env.REACT_APP_IMAGES_URL}temnoparque/${content.file}`} alt="Tem no Parque" width="100%" />
                  </div>
                </div>
                <div>
                {(TemNoParqueGalerias.length >= 1) ? (
                  TemNoParqueGalerias.map(galeria => (
                   galeria.gallery !== '' ? (
                    <Fragment key={galeria.id}>
                      <h1 className={styles.galleryTitle} key={galeria.id}>{galeria.titleGallery}</h1>
                      <div className={styles.gallery}>
                        {galeria.gallery.split(',').map((image, index) => (
                            <div key={index}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}temnoparquegalerias/${image}`} alt={`Imagem ${index}`} onClick={(e) => handleModal(e, image)} />
                          </div>
                        ))}
                      </div>
                    </Fragment>
                   ) : ''
                  ))
                ) : ''
                }
                {TemNoParqueGalerias.length >= 1 ? (
                  TemNoParqueGalerias.map(galeria => (
                    galeria.videos !== '' ? (
                      <Fragment key={galeria.id}>
                        <h1 className={styles.galleryTitle}>{galeria.videosTitle}</h1>
                        <div className={styles.videos}>
                          {galeria.videos.split(',').map((video, index) => (
                            <div key={index}>
                              <iframe src={`https://www.youtube.com/embed/${video}`} title={`Vídeo ${index}`}></iframe>
                            </div>
                          ))}
                        </div>
                      </Fragment>
                    ) : ''
                  ))
                ) : ''
                }
                </div>
              </div>
            </section>
          </div>
        ))}
        <section id={styles.temNoParqueBox}>
          <div className={styles.temNoParqueBoxContent}>
            {TemNoParqueDestaques.map(content => (
              <Link to={`${content.slug}`} key={content.id}>
                <div style={{backgroundImage:`url(${process.env.REACT_APP_IMAGES_URL}temnoparquedestaques/${content.file})`}}>
                  <div className={styles.temNoParqueBoxFilter}>
                    <div className={styles.card}>
                      <h1>{content.title}</h1>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </section>
      </main>
    </>
  );
}
