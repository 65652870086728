import { GrClose } from 'react-icons/gr';

import styles from './styles.module.scss';

export function ModalGallery(props) {

    console.log(props);
   
    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
            <GrClose size={30} color="#ffffff" className={styles.modalIcon} onClick={props.close} />
                <div className={styles.modal}>
                   
                    <div className={styles.Content}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}${props.folder}/${props.image}`} alt="Galeria" width="100%" />
                    </div>
                </div>

            </div>
        </>
    );
}
